
import { defineComponent, reactive, ref } from 'vue';

import SVG from '@/components/SVG.vue';
import Loader from '@/components/Loader.vue';
import Input from '@/components/form/Input.vue';
import AuthService from '@/services/auth.service';
import { useRoute } from 'vue-router';

export default defineComponent({
	components: {
		SVG,
		Loader,
		Input,
	},

	setup() {
		const route = useRoute();

		const loading = ref(false);
		const success = ref(false);
		const error = ref('');

		const form = reactive({
			email: '',
			password: '',
			password_confirmation: '',
		});

		function validate() {
			if (form.email.length === 0) {
				error.value = 'Teldupostur manglar';
				return false;
			}

			if (form.password.length < 8) {
				error.value = 'Loyniorðið skal vera minst 8 stavir';
				return false;
			}

			if (form.password !== form.password_confirmation) {
				error.value = 'Loyniorðini skullu vera líka';
				return false;
			}

			return true;
		}

		async function submit() {
			if (!validate()) {
				return;
			}

			error.value = '';

			loading.value = true;

			const token = route.query.token?.toString();

			await AuthService.resetPassword({
				token: token || '',
				email: form.email,
				password: form.password,
				password_confirmation: form.password_confirmation,
			})
				.then((response) => {
					if (response?.status != 'success') {
						error.value = 'Ein feilur hendi, vinarliga royn aftur';

						return;
					}

					success.value = true;
				})

				.finally(() => {
					loading.value = false;
				});
		}

		return {
			// data
			form,
			loading,
			success,
			error,

			// functions
			submit,
		};
	},
});
